import React, { useEffect, useState, useRef } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './Latest.css';
import { Link } from 'react-router-dom';
import SlideUp from '../Animations/SlideUp/SlideUp';

const Latest = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const sectionRef = useRef(null);
  const [postLists, setPostLists] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const q = query(collection(firestore, "posts"), orderBy("timestamp", "desc"));
        const data = await getDocs(q);
        const newestPosts = data.docs.map(doc => ({ ...doc.data(), id: doc.id })).slice(0, 3);
        setPostLists(newestPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchPosts();

    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries[0].isIntersecting;

      if (isIntersecting && !hasAnimated) {
        setIsVisible(true);
        setHasAnimated(true);
      }
    });

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, [hasAnimated]);

  return (
    <div className="latest-container">
      <h2 className="latest-title">Recente Posts</h2>
      <hr className="latest-separator" />
      <div className="post-cards" ref={sectionRef}>
        {hasAnimated &&
          postLists.map((post, index) => (
            <SlideUp isVisible={isVisible} key={post.id} delay={index * 500}>
              <Link to={`/post/${post.id}`} key={post.id} className="post-link">
                <div className='post-card'>
                  <div
                    className="post-image"
                    style={{
                      backgroundImage: `url(${post.imageUrl})`,
                      height: `300px`,
                      filter: `sepia(50%) saturate(130%) hue-rotate(10deg)`
                    }}
                  />
                  <div className="post-content">
                    <h3 className="post-title">{post.title}</h3>
                    <p className="post-text">{post.content}</p>
                    {post.timestamp && (
                      <p className="post-timestamp">
                                          {new Date(post.timestamp.seconds * 1000).toLocaleString('eu-EU')}
                      </p>
                    )}
                  </div>
                </div>
              </Link>
            </SlideUp>
          ))}
      </div>
    </div>
  );
};

export default Latest;
