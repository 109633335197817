import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './Post.css'; // Import CSS file for styling
import DOMPurify from 'dompurify';

const Post = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postDoc = doc(firestore, 'posts', id); // Use the id from useParams
        const docSnap = await getDoc(postDoc);
        if (docSnap.exists()) {
          setPost({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [id]); // Add id to the dependency array to re-fetch post when id changes

  return (
    <div className="single-post">
      {post ? (
        <>
          <div className="post-image" style={{ backgroundImage: `url(${post?.imageUrl})` }}>
            <div className="overlay"></div> {/* Add overlay */}
          </div>
          <div className="single-post-content">
            <h3 className="single-post-title">{post?.title}</h3>
            <hr className="single-post-separator" />
            <p className="single-post-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post?.content) }}></p>
            {post?.timestamp && (
              <p className="single-post-timestamp">
                {new Date(post?.timestamp?.seconds * 1000).toLocaleString('eu-EU')}
              </p>
            )}
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Post;
