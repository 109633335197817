import React from 'react'
import './About.css'
import aboutImage1 from './About1.JPG';

export default function About() {
  return (
    <div className='about'>
      <div className='about-con'>
        
          <h1>Over Zijker</h1>
          <hr className="about-separator" />
          <p>Het begon allemaal in het personeelsblad van een scholengemeenschap ergens in
          het land. Dat blad was opgericht door personeelsleden van de school en heette ‘Het
          Plat en Leien Dakje’. Het verscheen wekelijks.</p>
          <br/>
          <p>Volgens haar herinnering schreef ze
          daarin in de jaren ’90 van de vorige eeuw. Het blad werd op vrijdagmorgen in de
          plastic postbakken gelegd en Zijker werd gretig gelezen. Maar ja, het blad van en
          voor het personeel moest zakelijker worden en later ook digitaal.</p>
          <br/>
          <p>Dat was het einde
          van ‘Het Plat en Leien Dakje’ en Zijker. Ondanks haar kritiek op de digitale wereld,
          begon Zijker aan haar grote digitale avontuur: een website! Dat ging tot en met de
          jaren van corona goed, maar toen werd de websiteorganisatie overgenomen door
          een ander bedrijf en Zijker kon niet met de bijkomende vernieuwingen uit de voeten.</p>
          <br/>
          <p>Eind 2022 is toen www.zijker.nl opgeheven. Gelukkig is er nu Ruben om te helpen
          met een, hopelijk lange reeks, van nieuwe Zijkers over het onderwijs op een nieuwe site.</p>
          <img className="about-image1" src={aboutImage1} alt="About" />
        </div>
      
    </div>
  )
}
