import { useSpring, animated } from 'react-spring';

export default function SlideUp({ isVisible, children, delay }) {
  const props = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(20px)', // Adjust the translation value
    from: { opacity: 0, transform: 'translateY(20px)' }, // Adjust the initial translation value
    config: { tension: 120, friction: 14, mass: 1 }, // Adjust the tension, friction, and mass for a smoother animation
    delay,
  });

  return <animated.div style={props}>{children}</animated.div>;
}
