import React, { useEffect, useRef, useState } from 'react';
import './AboutSection.css'; // Import CSS file for styling
import aboutImage from './About.JPG';
import FadeIn from '../Animations/FadeIn/FadeIn';
import { Link } from 'react-router-dom';

const AboutSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries[0].isIntersecting;

      if (isIntersecting && !hasAnimated) {
        setIsVisible(true);
        setHasAnimated(true);
      }
    });

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, [hasAnimated]);

  return (
    <div className="about-container" id="about">
      <div className="about-content2" ref={sectionRef}>
        <FadeIn isVisible={isVisible} delay={500}>
          <h2 className="about-title">Over Zijker</h2>
          <hr className="about-separator" />
          <p className="about-description">Zijker is lang geleden begonnen met zeiken over het onderwijs. Het is ‘zij die zeikt’...</p>
          <Link to="/about">
            <button className="about-button1">MEER LEZEN</button>
          </Link>
        </FadeIn>
      </div>
      <div className='about-content1'>
        <img className="about-image" src={aboutImage} alt="About" />
      </div>
    </div>
  );
};

export default AboutSection;
