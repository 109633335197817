import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Nav.css';

const Nav = () => {
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolledDown, setScrolledDown] = useState(false);
  const location = useLocation();
  const navMenuRef = useRef(null);

  useEffect(() => {
    const pageTitles = {
      '/': 'Zijker | Home',
      '/blog': 'Zijker | Blog',
      '/about': 'Zijker | Over',
      // Add more routes and their corresponding titles as needed
    };

    if (location.pathname.startsWith('/post/')) {
      document.title = 'Zijker | Post lezen';
    } else {
      document.title = pageTitles[location.pathname] || 'Zijker';
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
      setScrolledDown(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling
    } else {
      document.body.style.overflow = 'auto'; // Allow scrolling
    }
  }, [menuOpen]);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navMenuRef.current && !navMenuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isBlogOrSignInRoute = location.pathname === '/blog' || location.pathname === '/signin' || location.pathname === '/new' || location.pathname === '/about';

  return (
    <nav className={`nav ${scrolling || isBlogOrSignInRoute ? 'scrolled' : ''} ${scrolledDown ? 'scrolled-down' : ''}`}>
      <div className="nav-con">
        <div className="nav-logo">
          <Link to="/signin" className={`${scrolling || isBlogOrSignInRoute ? 'scrolled-link' : ''} zkr-link`}>ZKR</Link>
        </div>
        <div className={`nav-menu ${menuOpen ? 'open' : ''}`} ref={navMenuRef}>
          <ul onClick={closeMenu}>
            <li><Link to="/" className={`${scrolling || isBlogOrSignInRoute ? 'scrolled-link' : ''}`}>HOME</Link></li>
            <li><Link to="/blog" className={`${scrolling || isBlogOrSignInRoute ? 'scrolled-link' : ''}`}>BLOG</Link></li>
            <li><Link to="/about" className={`${scrolling || isBlogOrSignInRoute ? 'scrolled-link' : ''}`}>OVER</Link></li>
          </ul>
        </div>
        <div className={`nav-toggle ${scrolledDown || isBlogOrSignInRoute ? 'scrolled-down' : ''}`} onClick={toggleMenu}>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
