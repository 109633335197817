import React, { useEffect, useRef, useState } from 'react';
import './Header.css'; // Import CSS file for styling
import FadeIn from '../Animations/FadeIn/FadeIn';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const navbarHeightInPixels = 60; // Adjust this value according to your navbar height

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries[0].isIntersecting;
      setIsVisible(isIntersecting);
    });

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about');
    const targetPosition = aboutSection.offsetTop - navbarHeightInPixels; // Adjust the scroll target position
    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
  };

  return (
    <div className="header" ref={sectionRef}>
      <video className="header-video" autoPlay loop muted>
        <source src="Header.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay"></div>
      <FadeIn isVisible={isVisible} delay={500}>
        <div className="header-content">
          <h1 className="header-title">ZIJKER</h1>
          <p className="header-subtitle">Hoe was het op school?</p>
          <div className="button-container">
            <button className="header-button1" onClick={scrollToAbout}>MEER INFORMATIE</button>
            <Link to="/blog">
              <button className="header-button2">BLOG LEZEN</button>
            </Link>
          </div>
        </div>
      </FadeIn>
    </div>
  );
};

export default Header;
