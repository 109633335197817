import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { firestore, storage } from '../../firebase';
import './NewPostForm.css'; // Import CSS file for styling
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

const NewPostForm = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const postsCollectionRef = collection(firestore, "posts");
  const navigate = useNavigate();

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleContentChange = (e) => setContent(e.target.value);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleSubmission = async () => {
    try {
      const timestamp = serverTimestamp();
      const storageRef = ref(storage, `images/${image.name}`);
  
      const snapshot = await uploadBytes(storageRef, image);
      const imageUrl = await getDownloadURL(snapshot.ref);
  
      // Replace line breaks with HTML line breaks
      const formattedContent = content.replace(/\n/g, '<br>');
  
      const docRef = await addDoc(postsCollectionRef, {
        title,
        content: formattedContent, // Use the formatted content with HTML line breaks
        imageUrl,
        timestamp,
      });
      const newPostId = docRef.id;
      navigate(`/post/${newPostId}`);
    } catch (error) {
      console.error("Error adding new post:", error);
    }
  };

  return (
    <div className="new-post-container">
      <h2 className="new-post-title">Nieuwe Post Maken</h2>
      <hr className="new-post-separator" />
      <div className="new-post-form">
        <div className="new-post-form-con">
        <div className="new-post-form-con2">
            <label htmlFor="image">Afbeelding:</label>
            <input type="file" id="image" onChange={handleImageChange} />
          </div>
          <div className="new-post-form-con2">
            <label htmlFor="title">Titel:</label>
            <input type="text" placeholder='Titel' id="title" value={title} onChange={handleTitleChange} />
          </div>
        </div>
          <div className="new-post-form-con2">
          <label htmlFor="content">Tekst:</label>
          <textarea id="content" placeholder='Tekst' value={content} onChange={handleContentChange} style={{ whiteSpace: 'pre-wrap' }}></textarea>
        </div>
        <button className="create-post-button" onClick={handleSubmission}>POST MAKEN</button>
      </div>
    </div>
  );
};

export default NewPostForm;
