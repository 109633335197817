import { useSpring, animated } from 'react-spring';

export default function FadeIn({ isVisible, children, delay = 0 }) {
  const props = useSpring({
    opacity: isVisible ? 1 : 0,
    from: { opacity: 0 },
    config: { tension: 120, friction: 14, mass: 1 }, // Adjust the tension, friction, and mass for a smoother animation
    delay,
  });

  return <animated.div style={props}>{children}</animated.div>;
}
