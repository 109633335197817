import React, { useState } from 'react';
import { auth } from './firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Navigate } from 'react-router-dom';
import './Authentication.css'; // Import CSS file for styling

const Authentication = ({ setAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirectToCreatePost, setRedirectToCreatePost] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful');
      setAuthenticated(true);
      setRedirectToCreatePost(true);
    } catch (error) {
      setError(error.message);
    }
  };

  if (redirectToCreatePost) {
    return <Navigate to="/new" />;
  }

  return (
    <div className="authentication-container">
      <h1 className="authentication-title">Login</h1>
      <hr className="authentication-separator" />
      <div className="authentication">
        <div className="authentication-form">
            <label className="authentication-label">Email:</label>
            <input className="authentication-input" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <label className="authentication-label">Wachtwoord:</label>
            <input className="authentication-input" type="password" placeholder="Wachtwoord" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button className="authentication-button" onClick={handleLogin}>LOGIN</button>
          {error && <p className="error-message">Gegevens kloppen niet</p>}
        </div>
      </div>
    </div>
  );
};

export default Authentication;
