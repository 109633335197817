import React, { useEffect, useRef, useState } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './Blog.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';
import SlideUp from '../Animations/SlideUp/SlideUp';

const Blog = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const sectionRef = useRef(null);
  const [postLists, setPostLists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const q = query(collection(firestore, "posts"), orderBy("timestamp", "desc"));
        const data = await getDocs(q);
        const allPosts = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setPostLists(allPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchPosts();

    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries[0].isIntersecting;

      if (isIntersecting && !hasAnimated) {
        setIsVisible(true);
        setHasAnimated(true);
      }
    });

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, [hasAnimated]);

  useEffect(() => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = postLists.slice(indexOfFirstPost, indexOfLastPost); // Corrected slicing
    setCurrentPosts(currentPosts); // Update currentPosts
  }, [currentPage, postLists, postsPerPage]);

  const [currentPosts, setCurrentPosts] = useState([]);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="blog">
      <h2 className="blog-title">Blog</h2>
      <hr className="blog-separator" />
      <div className="blog-post-cards" ref={sectionRef}>
        {hasAnimated &&
          currentPosts.map((post, index) => (
            <SlideUp isVisible={isVisible} key={post.id} delay={index * 500}>
              <Link to={`/post/${post.id}`} key={post.id} className="blog-post-link">
                <div className="blog-post-card">
                  <div className="blog-post-content">
                    <h3 className="blog-post-title">{post.title}</h3>
                    <p className="blog-post-text">{post.content}</p>
                    {post.timestamp && (
                      <p className="blog-post-timestamp">
                        {new  Date(post.timestamp.seconds * 1000).toLocaleString('eu-EU')}
                      </p>
                    )}
                  </div>
                </div>
              </Link>
            </SlideUp>
          ))}
      </div>
      <div className="pagination">
        {Array.from({ length: Math.ceil(postLists.length / postsPerPage) }, (_, i) => (
          <button className="blog-button" key={i} onClick={() => paginate(i + 1)}>{i + 1}</button>
        ))}
      </div>
    </div>
  );
};

export default Blog;
