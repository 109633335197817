import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header/Header'; // Import Header component
import Footer from './components/Footer/Footer'; // Import Footer component
import Latest from './components/Latest/Latest';
import Blog from './components/Blog/Blog';
import Post from './components/Post/Post';
import NewPostForm from './components/NewPostForm/NewPostForm';
import Nav from './components/Nav/Nav';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Authentication from './Authentication';
import AboutSection from './components/AboutSection/AboutSection';
import About from './components/About/About';
import NotFound from './components/NotFound';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // Set the authenticated state based on your authentication logic
    // For simplicity, assuming authentication logic is managed in Authentication component
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Nav/>
      <div>
        <Routes>
          <Route path="/" exact element={<><Header/> <Latest /> <AboutSection /></>} />
          <Route path="/blog" element={<Blog />} />
          <Route path='/about' element={<About />} />
          <Route path="/post/:id" element={<Post />} />
          <Route path="/signin" element={<Authentication setAuthenticated={setAuthenticated} />} />
          <Route path="/new" element={<PrivateRoute element={<NewPostForm />} authenticated={authenticated} />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
        </Routes>
      </div>
      <Footer/>
    </Router>
  );
};

export default App;
