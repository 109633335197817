import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAR_UshWKdGDNYm5LK5ff86effwYII25QA",
  authDomain: "zijker2.firebaseapp.com",
  projectId: "zijker2",
  storageBucket: "zijker2.appspot.com",
  messagingSenderId: "336591735131",
  appId: "1:336591735131:web:cc84470021935d50d3727e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, storage };